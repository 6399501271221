<template>
  <div v-html="markdown" class="markdown"></div>
</template>
<script>
var md = require('markdown-it')({ breaks: true });
export default {
  name: 'Markdown',
  props: ['value'],
  computed: {
    markdown() {
      if (this.value) {
        return md.render(this.value);
      }
      return '...';
    },
  },
};
</script>
<style scoped>
.markdown :deep(h1),
.markdown :deep(h2),
.markdown :deep(h3),
.markdown :deep(h4) {
  font-size: medium !important;
  font-weight: bold !important;
}
.markdown :deep(> ul) {
  padding-bottom: 2ex;
}
</style>
